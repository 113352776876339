import React, { useContext, useEffect, useState } from "react";
import { Link } from "gatsby";
import { useForm } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import PageWrapper from "../components/PageWrapper";
import { DataContext } from "../context/DataContext";
import { AuthContext } from "../context/AuthContext";
import { validation } from "../utils/validation";
import { getExchangeRate } from "../services/tokenConverter";

import imageNotFound from "../assets/image/image-not-found.jpg";

const TalentsList = ({ location }) => {
  const [loading, setLoading] = useState(true);
  const { register, handleSubmit, watch } = useForm();
  const watchKeyword = watch("keyword");
  const [exchangeRate, setExchangeRate] = useState(0);
  const [searchResults, setSearchResults] = useState([]);
  const { talents, getAllTalentList } = useContext(DataContext);
  const { auth } = useContext(AuthContext);

  const handleSearchInput = async (data) => {
    const searchKeyword = data.toLocaleLowerCase();
    const result = talents.filter(
      (talent) =>
        talent.title.toLocaleLowerCase().includes(searchKeyword) ||
        talent.skills?.toString()
          .toLocaleLowerCase()
          .includes(searchKeyword) ||
        talent.experience?.toString()
          .toLocaleLowerCase()
          .includes(searchKeyword) ||

        talent.otherSkills?.toString()
          .toLocaleLowerCase()
          .includes(searchKeyword)
    );
    setSearchResults(result);
  };

  const fetchTalents = async () => {
    await fetchExchangeRate();
    const response = await getAllTalentList();

    if (location?.state?.searchKeyword) {
      const data = response.filter(
        (talent) =>
          talent.title
            .toLocaleLowerCase()
            .includes(location.state.searchKeyword) ||
          talent.skills
            .toString()
            .toLocaleLowerCase()
            .includes(location.state.searchKeyword) ||
          talent.experience?.toString()
            .toLocaleLowerCase()
            .includes(location.state.searchKeyword) ||
          talent.otherSkills?.toString()
            .toLocaleLowerCase()
            .includes(location.state.searchKeyword)
      );
      setSearchResults(data);
    } else {
      setSearchResults(response);
    }
    setLoading(false);
  };

  const fetchExchangeRate = async () => {
    const rate = await getExchangeRate();
    setExchangeRate(rate);
  };

  useEffect(() => {
    fetchTalents();
    return () => setLoading(true);
  }, []);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const { keyword } = data;
      const searchKeyword = keyword.toLocaleLowerCase();
      const response = talents.filter(
        (talent) =>
          talent.title.toLocaleLowerCase().includes(searchKeyword) ||
          talent.skills
            .toString()
            .toLocaleLowerCase()
            .includes(searchKeyword)
      );
      setSearchResults(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <PageWrapper>
        <div className="bg-default-1 pt-26 pt-lg-28 pb-13 pb-lg-25">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <form
                  autoComplete="off"
                  onSubmit={handleSubmit(onSubmit)}
                  className="search-form"
                >
                  <div className="filter-search-form-2 search-1-adjustment bg-white rounded-sm shadow-7 px-6 py-6 pl-6">
                    <div className="filter-inputs">
                      <div className="form-group position-relative w-100">
                        <input
                          className="form-control focus-reset pl-13"
                          type="text"
                          id="keyword"
                          name="keyword"
                          onChange={(e) => handleSearchInput(e.target.value)}
                          defaultValue={location?.state?.searchKeyword}
                          placeholder="Talent role or skill"
                          ref={register(validation.search)}
                        />
                        <span className="h-100 w-px-50 pos-abs-tl d-flex align-items-center justify-content-center font-size-6">
                          <i className="icon icon-zoom-2 text-primary font-weight-bold"></i>
                        </span>
                      </div>
                    </div>
                    <div className="button-block">
                      <button
                        type="submit"
                        className="btn btn-primary line-height-reset h-100 btn-submit w-100 text-uppercase focus-reset"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </form>

                <div className="pt-12">
                  <div className="d-flex align-items-center justify-content-between mb-6">
                    <h5 className="font-size-4 font-weight-normal text-gray">
                      <span className="heading-default-color mr-2">
                        {
                          searchResults.filter(
                            (talent) => talent.status === "active"
                          ).length
                        }
                      </span>
                      results for{" "}
                      <span className="heading-default-color">
                        {location?.state?.searchKeyword ||
                          watchKeyword ||
                          "All"}
                      </span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="pt-4 w-100 mx-4">
                {loading ? (
                  <div className="row">
                    {[1, 2, 3, 4].map((index) => (
                      <div
                        key={index}
                        className="col-xs-12 col-sm-4 col-md-6 col-lg-3"
                      >
                        <div className="bg-white px-4 pt-3 pb-4 rounded-4 mb-9 feature-cardOne-adjustments">
                          <div className="text-right mb-2">
                            <Skeleton width={80} height={26} />
                          </div>
                          <div className="d-flex justify-content-center mb-3">
                            <Skeleton
                              width={120}
                              height={120}
                              className="rounded-circle"
                            />
                          </div>
                          <h3 className="text-center mt-n2">
                            <Skeleton width={180} height={36} />
                          </h3>
                          <div className="text-center mb-4">
                            <Skeleton width={150} height={26} />
                          </div>
                          <ul className="list-unstyled mb-1 card-tag-list justify-content-center mb-4">
                            <li>
                              <Skeleton
                                width={100}
                                height={26}
                                className="mr-3"
                              />
                            </li>
                            <li>
                              <Skeleton width={100} height={26} />
                            </li>
                          </ul>
                          <div className="card-btn-group justify-content-center mb-4">
                            <Skeleton height={40} containerClassName="w-50" />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="row">
                    {searchResults.map((talent, index) =>
                      talent.status === "active" ? (
                        <div
                          key={index}
                          className={`${searchResults.length === 1
                            ? "col-3"
                            : searchResults.length === 2
                              ? "col-xs-12 col-sm-3"
                              : "col-xs-12 col-sm-4 col-md-6 col-lg-3"
                            }`}
                        >
                          <div
                            className={`bg-white px-4 pt-5 pb-3 rounded-4 mb-9 feature-cardOne-adjustments ${talent?.featured ? "border border-primary" : null
                              }`}
                          >
                            <div className="d-flex justify-content-end mb-4">
                              <span className="bg-regent-opacity-15 text-gray font-weight-bold font-size-3 rounded-3 px-4 py-2">
                                {`${talent.currency === "USDT"
                                  ? talent.rate
                                  : Math.floor(
                                    (talent.rate * exchangeRate).toFixed(2)
                                  )
                                  } USDT/h`}
                              </span>
                            </div>
                            <div className="d-flex justify-content-center mb-4">
                              <Link
                                to={`/candidate-profile/${talent.account_id}`}
                                state={{ id: talent.account_id }}
                              >
                                {talent?.imageUrl ? (
                                  <img
                                    src={talent?.imageUrl}
                                    loading="lazy"
                                    alt={`${talent.firstname} ${talent.firstname} Devshop profile`}
                                    height={120}
                                    className="talent-list-image rounded-circle"
                                  />
                                ) : (
                                  <img
                                    src={imageNotFound}
                                    alt="Not found"
                                    width={120}
                                    className="rounded-circle"
                                  />
                                )}
                              </Link>
                            </div>
                            <h2 className="mt-n4 mb-n1 text-center">
                              <Link
                                to={`/candidate-profile/${talent.account_id}`}
                                state={{ id: talent.account_id }}
                                className="font-size-5 text-black-2 font-weight-bold"
                              >
                                {`${talent.firstname} ${talent.lastname.charAt(
                                  0
                                )}`}
                              </Link>
                            </h2>
                            <Link
                              to={`/candidate-profile/${talent.account_id}`}
                              state={{ id: talent.account_id }}
                              className="font-size-3 d-block mb-2 text-gray text-center"
                            >
                              {talent?.title}
                            </Link>
                            <div>
                              <ul className="d-flex list-unstyled mr-n3 flex-wrap justify-content-center mb-3 max-width-px-294 max-height-px-32">
                                {talent?.skills &&
                                  talent.skills
                                    .slice(0, 3)
                                    .map((skill, index) =>
                                      skill === "Other" ? null : (
                                        <li key={index}>
                                          <Link
                                            to="/#"
                                            onClick={(e) => e.preventDefault()}
                                            className="bg-polar text-black-2 mr-3 px-3 mt-1 mb-1 font-size-3 rounded-3 min-height-32 d-flex align-items-center"
                                          >
                                            {skill}
                                          </Link>
                                        </li>
                                      )
                                    )}

                                {talent?.otherSkills &&
                                  talent?.otherSkills
                                    .split(",")
                                    .splice(0, 3)
                                    .map((skill, index) => (
                                      <li key={index}>
                                        <Link
                                          to="/#"
                                          onClick={(e) => e.preventDefault()}
                                          className="bg-polar text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-3 min-height-32 d-flex align-items-center"
                                        >
                                          {skill.trim()}
                                        </Link>
                                      </li>
                                    ))}
                              </ul>
                            </div>
                            {/* <ul className="list-unstyled mb-1 card-tag-list justify-content-center">
                              <li>
                                <Link
                                  to="#"
                                  onClick={(e) => e.preventDefault()}
                                  className="bg-regent-opacity-15 text-denim font-size-3 rounded-3"
                                >
                                  <i className="icon icon-pin-3 mr-2 font-weight-bold"></i>{" "}
                                  {talent?.city}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="#"
                                  onClick={(e) => e.preventDefault()}
                                  className="bg-regent-opacity-15 text-green font-size-3 rounded-3"
                                >
                                  <i className="fa fa-star mr-2 font-weight-bold"></i>{" "}
                                  {`${talent?.rating || 0}/5`}
                                </Link>
                              </li>
                            </ul> */}
                            <div className="card-btn-group justify-content-center mt-4 px-4 px-sm-0">
                              {auth?.talent || !auth ? null : (
                                <Link
                                  to={`/hire-talent/${talent.account_id}`}
                                  state={{ id: talent.account_id }}
                                  className="btn btn-green text-uppercase btn-small rounded-3 focus-reset w-50"
                                >
                                  Hire Me
                                </Link>
                              )}
                              {/* {!loading && auth && auth?.client ? (
                                  <button
                                    type="button"
                                    className="btn btn-outline-mercury text-black-2 text-uppercase btn-medium rounded-3 focus-reset"
                                    onClick={() => handleSaveTalents(talent)}
                                    // disabled={savedTalents.includes(talent.account_id) ? true : false}
                                  >
                                    <i className="icon icon-bookmark-2 font-weight-bold mr-4 font-size-4"></i>{" "}
                                    Save
                                  </button>
                                ) : null} */}
                            </div>
                          </div>
                        </div>
                      ) : null
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default TalentsList;
